import product1 from './assets/products/1.png'
import product2 from './assets/products/2.png'
import product3 from './assets/products/3.png'
import product4 from './assets/products/4.png'
import product5 from './assets/products/5.png'
import product6 from './assets/products/6.webp'
import product7 from './assets/products/7.webp'
import product8 from './assets/products/8.webp'

export const PRODUCTS = [
  {
    id: 1,
    productName: 'Hoodie',
    price: 59.0,
    productImage: product1,
  },
  {
    id: 2,
    productName: 'Pepsi 0.33',
    price: 0.99,
    productImage: product2,
  },
  {
    id: 3,
    productName: 'Cannon M50 Camera',
    price: 699.0,
    productImage: product3,
  },
  {
    id: 4,
    productName: 'Iphone 11',
    price: 559.0,
    productImage: product4,
  },
  {
    id: 5,
    productName: 'Samsung Galaxy',
    price: 449.0,
    productImage: product5,
  },
  {
    id: 6,
    productName: 'Motorola',
    price: 599.0,
    productImage: product6,
  },
  {
    id: 7,
    productName: 'Helicopter Tour',
    price: 129.0,
    productImage: product7,
  },
  {
    id: 8,
    productName: 'IPad',
    price: 490.0,
    productImage: product8,
  },
]
